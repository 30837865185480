var calcTech = $(".js-calc-tech");


function calcTechfunc() {


    let Total = 0,
        timeCount = $(".js-calc-tech-time:checked").data("time"),
        typeWork = $(".js-calc-tech-type:checked").data("type"),

        count = calcSmm.find($(".js-calc-smm-item[data-name='social']:checked")).length

        console.log("timeCount = "  ,timeCount , typeWork);

        if (typeWork != "pod") {
            Total = Total + 1500 * timeCount;

            if (timeCount == 0) {
                Total = 1500
            }
        } else {
            if (timeCount == 1) {
                Total = 1500;
            } else if (timeCount == 10) {
                console.log("Total = "  ,Total);
                Total = 14700;
            } else if (timeCount == 20) {
                Total = 28500;
            } else if (timeCount == 30) {
                Total = 40500;
            } else if (timeCount == 0) {
                Total = 1500;
            }
        }

        console.log("Total = "  ,Total);

        if ($(".js-calc-tech-switch").prop("checked") == true) {
            Total = Total + Total * 0.2;
        }

    /*
    if(count > 1) {
        Total = Total + 5000*(count - 1);
    }

    if ($(".js-calc-tech-item[data-name='design']").prop("checked") == true) {
        Total = Total + 2000;
    }

    if ($(".js-calc-tech-item[data-name='marketing']").prop("checked") == true) {
        Total = Total + 5000;
    }*/


    Total=Total.toString();

    $(".js-calc-tech-total").text(Total.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + ' '))
}


$(".js-calc-tech-item").change(function () {
    calcTechfunc($(this));
});

